// Font families, weights and styles.

@import url("https://use.typekit.net/tks6cme.css");

@font-face {
  font-family: MonumentExtended-Bold;
  font-weight: 700;
  src: url('/static/fonts/MonumentExtended-Bold.otf') format('opentype');
}

@font-face {
  font-family: MonumentExtended-Regular;
  font-weight: 400;
  src: url('/static/fonts/MonumentExtended-Regular.otf') format('opentype');
}

@font-face {
  font-family: Manrope-Bold;
  font-weight: 700;
  src: url('/static/fonts/Manrope-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: Manrope-Regular;
  font-weight: 400;
  src: url('/static/fonts/Manrope-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: OpenSans-Regular;
  font-weight: 400;
  src: url('/static/fonts/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
}

@mixin pnm {
  font-family: proxima-nova, sans-serif; 
  font-weight: 500;
}

@mixin pnr {
  font-family: proxima-nova, sans-serif; 
  font-weight: 400;
}

@mixin manr {
  font-family: Manrope-Regular;
  font-weight: 400;
}

@mixin manb {
  font-family: Manrope-Regular;
  font-weight: 700;
}

@mixin sr {
  font-family: 'Syncopate', sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin sb {
  font-family: 'Syncopate', sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin mr {
  font-family: MonumentExtended-Regular;
  font-weight: 400;
  font-style: normal;
}

@mixin mb {
  font-family: MonumentExtended-Bold;
  font-weight: 700;
  font-style: normal;
}

@mixin mrb {
  font-family: 'Manrope', sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin pr {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin psb {
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin pb {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin osr {
  font-family: OpenSans-Regular;
  font-weight: 400;
}


@mixin gradient--a {
    //  background: rgb(45,26,173);
    //  background: linear-gradient(90deg, rgba(45,26,173,1) 0%, rgba(111,70,226,1) 25%, rgba(199,98,212,1) 50%, rgba(198,77,101,1) 75%, rgba(237,202,114,1) 100%);
    background: rgb(0,136,198);
    background: linear-gradient(180deg, rgba(0,136,198,1) 0%, rgba(0,177,217,1) 75%, rgba(0,177,217,1) 100%);
    opacity: 1;
}

@mixin gradient--b {
  //  background: rgb(45,26,173);
  //  background: linear-gradient(270deg, rgba(45,26,173,1) 0%, rgba(111,70,226,1) 25%, rgba(199,98,212,1) 50%, rgba(198,77,101,1) 75%, rgba(237,202,114,1) 100%);
  background: rgb(0,136,198);
  background: linear-gradient(180deg, rgba(0,136,198,1) 0%, rgba(0,177,217,1) 75%, rgba(0,177,217,1) 100%);
  opacity: 1;
}

@mixin gradient--c {
  background: rgb(45,26,173);
  background: linear-gradient(135deg, rgba(45,26,173,1) 0%, rgba(111,70,226,1) 25%, rgba(199,98,212,1) 50%, rgba(198,77,101,1) 75%, rgba(237,202,114,1) 100%);
  opacity: 1;
}

@mixin gradient--d {
  background: rgba(0,178,217,1);
  background: linear-gradient(90deg, rgba(0,178,217,1) 0%, rgba(14,147,94,1) 100%);
  opacity: 1;
}

@mixin gradient--e {
  background: rgb(0,136,198);
  background: linear-gradient(180deg, rgba(0,136,198,1) 0%, rgba(0,177,217,1) 80%, rgba(0,177,217,1) 100%); 
  opacity: 1;
}

@mixin gradient--f {
  background: rgb(235,85,88);
  background: linear-gradient(180deg, rgba(235,85,88,1) 0%, rgba(221,130,37,1) 100%);
  opacity: 1;
}

@mixin background-gradient {
  background: #171715;
  background: linear-gradient(135deg, rgba(23,23,21,1) 0%, rgba(160,160,160,1) 100%);
}

@mixin background-gradient-b {
  background: #171715;
  background: linear-gradient(100deg, rgba(23,23,21,1) 0%, #343434 100%);
}

@mixin gradient--g {
  background: rgb(0,136,198);
  background: linear-gradient(165deg, rgba(0,136,198,1) 0%, rgba(0,177,217,1) 75%, rgba(0,177,217,1) 100%);
  opacity: 1;
}


@mixin header--mb100 {
  @include mb;
  font-size: 100px;
  line-height: 120px;
  letter-spacing: -0.025em;

  @media(max-width: $break-0){
    font-size: 25px;
    line-height: 30px;
  }

}

@mixin header--mb34 {
  @include mb;
  font-size: 34px;
  line-height: 38px;

  @media(max-width: $break-0){
    font-size: 16px;
    line-height: 24px;
  }

}

@mixin header--mb24 {
  @include mb;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.070em;
}

@mixin header--mb22 {
  @include mb;
  font-size: 22px;
  line-height: 24px;
  text-transform: uppercase;

  @media(max-width: $break-0){
    font-size: 16px;
  }
}

@mixin header--mr22 {
  @include mr;
  font-size: 22px;
  line-height: 24px;
  text-transform: uppercase;

  @media(max-width: $break-0){
    font-size: 16px;
  }
}

@mixin header--mb18 {
  @include mb;
  font-size: 18px;
  line-height: 24px;
}

@mixin paginate--mb16 {
  @include mb;
  font-size: 16px;
  line-height: 24px;
}

@mixin company--mb14 {
  @include mb;
  font-size: 14px;
  line-height: 24px;
}

@mixin back--mb12 {
  @include mb;
  font-size: 12px;
  line-height: 26px;

  @media(max-width: $break-0){
    font-size: 10px;
    line-height: 22px;
  }

}

@mixin header--sr34 {
  @include sr;
  font-size: 34px;
  line-height: 38px;

  @media(max-width: $break-0){
    font-size: 24px;
    line-height: 26px;
  }

}

@mixin menu--sr12 {
  @include sr;
  font-size: 12px;
  line-height: 26px;
}

@mixin share--pb17 {
  @include pb;
  font-size: 17px;
  line-height: 26px;
}

@mixin field--psb {
  @include psb;
  font-size: 12px;
  line-height: 14px
}

@mixin menu--pr16 {
  @include pr;
  font-size: 16px;
  line-height: 26px;
}

@mixin depart--pr14 {
  @include pr;
  font-size: 14px;
  line-height: 24px;
}

@mixin para--pr22 {
  @include pr;
  font-size: 22px;
  line-height: 32px;

  @media(max-width: $break-0){
    fobnt-size: 16px;
    line-height: 26px;
  }

}

@mixin icons--18 {
  font-size: 18px;
  line-height: 26px;
}

@mixin icons--20 {
  font-size: 20px;
  line-height: 26px;
}

@mixin icons--25 {
  font-size: 25px;
  line-height: 25px;
}

@mixin seperator {
  display: block;
  width: 42px;
  @include gradient--a;
  &:not([size]) {
    height: 2px;
  }
}

@mixin seperator-wide {
  display: block;
  border-bottom: 1px solid $alto;
  &:not([size]) {
    height: 2px;
  }
}

@mixin seperator-center {
  margin-top: 0;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  @include seperator;
}

@mixin shadow {
  box-shadow: 0px 3px 19px 0px rgba(65,65,65,0.16);
}


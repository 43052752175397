.s-share {
  #background-color: $whisper;
  padding-bottom: 70px;

  &__title {
    margin-right: 28px;
    @include share--pb17;
    color: $woodsmoke;
  }

  &__icon {
    margin-left: 7px;
    margin-right: 7px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 22px;
      border: 2px solid $silver-sand;
      width: 44px;
      height: 44px;
      font-size: 15px;
      line-height: 15px;
      text-decoration: none;
      color: $woodsmoke;
      &:hover {
        color: $woodsmoke;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @media(max-width: $break-1){
    padding-bottom: 50px;
    &__title {
      display: block;
      margin-right: 0px;
      margin-bottom: 15px;
    }
    &__icon {
      margin-bottom: 14px;
    }
  }

}

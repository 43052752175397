h1, h2, h3, h4, h5, h6 {
  &.inherit {
    all: inherit;
    margin: 0;
    padding: 0;
  }
}

.errorlist {
  display: inline;
  padding-left: 0;
  li {
    display: inline;
    color: $bs-red;
  }
}

.lift-up {
  position: relative;
  top: -30px;
  visibility: hidden;
}

.grecaptcha-badge {
  visibility: hidden;
}
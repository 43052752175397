.s-newsbody {
    background-color: $white;
    padding-top: 50px;
    color: $black;

    &__title {
        margin-bottom: 30px;
        @include mb;
        font-size: 15px;
        line-height: 23px;
        text-transform: uppercase;
    }

    &__content {
        margin-bottom: 75px;
        @include pnr;
        font-size: 16px;
        line-height: 26px;

        p {
            margin-bottom: 0;
        }
    }

    &__image {
        margin-bottom: 75px;
        img {
            width: 100%;
        }
    }

    @media(max-width: $break-0){
        padding-top: 40px;
        &__content {
            margin-bottom: 20px;
            font-size: 18px;
            line-height: 24px;
        }
        &__image {
            margin-bottom: 40px;
        }
    }
}
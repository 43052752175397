.s-page {
  #background-color: $whisper;
  padding-top: 70px;
  padding-bottom: 100px;

  &__title {
    margin-bottom: 70px;
    @include header--mb24;
    color: $white;
  }

  &__seperator {
    @include seperator;
  }

  &__content {
    @include para--pr22;
    color: $white;

    p {
      margin-bottom: 70px;
    }
  }

  &__droid {
    margin-bottom: 40px;
    text-align: center;
    filter: invert(48%);
  }

  @media(max-width: $break-0){
    padding-bottom: 0px;

    padding-left: 12px;
    padding-right: 12px;

    &__title {
      margin-bottom: 65px;
      font-size: 22px;
      line-height: 33px;
    }
  }

}
.s-cslist {

  $self: &;

  padding-top: 65px;

  &__title {
    margin-bottom: 25px;
    @include mb;
    font-size: 21px;
    line-height: 32px;
    text-transform: uppercase;
    text-align: center;
    color: $whisper;
  }

  &__content {
    margin-bottom: 30px;
    @include manr;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: $white;
  }

  &__seperator {
    @include seperator-center;
    margin-bottom: 70px;
    &--case {
      @include gradient--d;
      margin-bottom: 30px;
    }
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__dsk {
    display: block;
  }
  &__mob {
    display: none;
  }

  &--pt-0 {
    padding-top: 0px;
  }

  &--pb-60 {
    padding-bottom: 60px;
  }

  &--inverse {
    background-color: $white;

    #{$self}__title {
      color: $black;
    }
  }

  @media(max-width: $break-0){
    padding-bottom: 60px;

    &__title {
      font-size: 22px;
      line-height: 24px;
      text-align: left;
    }

    &__content {
      font-size: 16px;
      line-height: 24px;
      text-align: left;
    }

    &__dsk {
      display: none;
    }
    &__mob {
      display: block;
    }

    &__seperator {
      margin-left: 0;
      margin-bottom: 40px;
    }

    &--stay-center {
      #{$self}__seperator {
        margin-left: auto;
      }
      #{$self}__title {
        text-align: center;
      }
    }

  }

  @media(max-width: $break-A){
    &__grid {
      margin-left: -.75rem;
      width: calc(100% + 1.5rem);
    }
  }

}

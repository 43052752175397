.s-faqs {

  padding-bottom: 100px;

  &__title {
    margin-bottom: 30px;
    @include header--mb22;
    text-align: center;
    color: $white;
  }

  &__seperator {
    @include seperator-center;
    margin-bottom: 100px;
  }

  &__grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 100px;
  }

  &__item {
    flex-basis: calc(33.33% - 100px);
  }

  &__subtitle {
    margin-bottom: 15px;
    @include paginate--mb16;
    color: $white;
  }

  &__content {
    margin-bottom: 80px;
    @include menu--pr16;
    color: $concrete;
  }

  @media(max-width: $break-1){
    &__item {
      flex-basis: calc(50% - 100px);
    }
  }


  @media(max-width: $break-0){
    &__grid {
      margin-left: 12px;
      width: calc(100% - 24px);
    }
    &__item {
      flex-basis: 100%;
    }
    &__title {
      padding-left: 12px;
      padding-right: 12px;
      text-align: left;
    }
    &__seperator {
      margin-bottom: 30px;
      margin-left: 12px;
    }
    &__order {
      display: flex;
      flex-direction: column-reverse;
    }
    &__content {
      margin-bottom: 40px;
    }
  }

}
.s-cookies {
  position: fixed;
  z-index: 100;
  display: none;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 80px;
  @include background-gradient-b;
  padding-left: 135px;
  padding-right: 90px;

  &__close {
    position: absolute;
    top:10px;
    right: 10px;
    width: 30px;
    height: 30px;
    margin-left: 30px;
    background-color: transparent;
    box-shadow: none;
    border: 0;
    font-size: 20px;
    line-height: 20px;
    color: $white;
  }

  &__content {
    @include pr;
    font-size: 16px;
    line-height: 26px;
    color: $white;

    a {
      text-decoration: underline;
      color: $white;
      &:hover {
        color: $white;
      }
    }

  }

  &--show {
    display: flex;
  }

  @media(max-width: $break-0){
    height: 130px;
    padding-left: 20px;
    padding-right: 20px;

    &__close {
      margin-left: 20px;
    }

    &__content {
      font-size: 13px;
      line-height: 16px;
    }
  }

}
.s-penquiry {

    margin-bottom: 200px;

    &__flex {
        display: flex;
        align-items: stretch;
        border-radius: 10px;
        overflow: hidden;
    }

    &__form {
        background: rgb(0,136,198);
        background: linear-gradient(180deg, rgba(0,136,198,1) 0%, rgba(0,177,217,1) 75%, rgba(0,177,217,1) 100%);
        width: 50%;
        padding: 40px 25px;

    }

    &__title {
        margin-bottom: 20px;
        @include mb;
        font-size: 30px;
        line-height: 33px;
        text-transform: uppercase;
        color: $white; 
    }

    &__content {
        margin-bottom: 20px;
        @include manr;
        font-size: 20px;
        line-height: 26px;
        color: $white; 
    }

    &__image {
        width: 50%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    @media(max-width: $break-1){
        &__form {
            width: 100%;
        }
        &__image {
            display: none;
        }
    }

    @media(max-width: $break-0){
        margin-bottom: 50px;
        &__form {
            padding: 20px 15px;
        }
        &__title {
            margin-bottom: 20px;
            font-size: 16px;
            line-height: 19px;
        }
        &__content {
            margin-bottom: 20px;
            font-size: 14px;
            line-height: 26px;
        }
    }

}
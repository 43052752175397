// Breakpoints

$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

$break-A: $sm - 1;
$break-0: $md - 1;
$break-1: $lg - 1;
$break-2: $md - 1;
$break-3: $xl - 1;
$break-4: $xxl - 1;

// Colours (Bootstrap)

$bs-blue:        #0d6efd;
$bs-indigo:      #6610f2;
$bs-purple:      #6f42c1;
$bs-pink:        #d63384;
$bs-red:         #dc3545;
$bs-orange:      #fd7e14;
$bs-yellow:      #ffc107;
$bs-green:       #198754;
$bs-teal:        #20c997;
$bs-cyan:        #0dcaf0;

// Colours (Custom)

$white:          #FFFFFF;
$black:          #000000;
$log-cabin:      #171717;
$whisper:        #F8F7FA;
$alto:           #D3D3D3;
$woodsmoke:      #121517;
$silver-sand:    #CACBCB;
$tundora:        #4B4B4B;
$cadet-blue:     #B1BAC5;
$alto:           #D0D0D0;

$cod-gray:       #171717;
$concrete:       #F2F2F2;
$cerulean:       #01B1D9;
@import url('https://fonts.googleapis.com/css2?family=Syncopate&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@700&display=swap');
@import url("https://use.typekit.net/oha8ufd.css");


@import '~jquery-ui/themes/base/all.css';

// Owl Carousel
@import "owl.carousel/dist/assets/owl.carousel.css";
@import "owl.carousel/dist/assets/owl.theme.default.css";

// Bootstrap
@import "~bootstrap/scss/functions";

//@import "~bootstrap/scss/variables";
@import "common/bsvars.scss";

@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/utilities/api";

$fa-font-path: "/static/fonts/webfonts";
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

@import "common/variables.scss";
@import "common/mixins.scss";
@import "common/global.scss";

@import "sections/s-body.scss";
@import "sections/s-header.scss";
@import "sections/s-hbanner.scss";
@import "sections/s-block.scss";
@import "sections/s-grid.scss";
@import "sections/s-para.scss";
@import "sections/s-touch.scss";
@import "sections/s-share.scss";
@import "sections/s-abutton.scss";
@import "sections/s-title.scss";
@import "sections/s-cslist.scss";
@import "sections/s-case.scss";
@import "sections/s-casec.scss";
@import "sections/s-footer.scss";
@import "sections/s-parau.scss";
@import "sections/s-ablock.scss";
@import "sections/s-faqs.scss";
@import "sections/s-contact.scss";
@import "sections/s-thanks.scss";
@import "sections/s-page.scss";
@import "sections/s-cookies.scss";
@import "sections/s-modal.scss";
@import "sections/s-padding.scss";
@import "sections/s-stats.scss";
@import "sections/s-newshead.scss";
@import "sections/s-newsbody.scss";
@import "sections/s-prodhead.scss";
@import "sections/s-prodbody.scss";
@import "sections/s-penquiry.scss";
@import "sections/s-feathead.scss";
@import "sections/s-featbody.scss";
@import "sections/s-pagehead.scss";
@import "sections/s-pagebody.scss";
@import "sections/s-figures.scss";

@import "comps/c-button.scss";
@import "comps/c-block.scss";
@import "comps/c-grid.scss";
@import "comps/c-article.scss";
@import "comps/c-cslist.scss";
@import "comps/c-casec.scss";
@import "comps/c-pagination.scss";
@import "comps/c-form.scss";
@import "comps/c-stat.scss";
@import "comps/c-key.scss";
@import "comps/c-figures.scss";
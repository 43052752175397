.s-featbody {

    $self: &;

    &__article {
        display: flex;
        align-items: stretch;
        padding-top: 30px;
        padding-bottom: 30px;
        &:nth-child(even){
            flex-direction: row-reverse;
            #{$self}__text {
                padding-right: 0;
                padding-left: 7.5%;
            }
        }

        &--reverse {
            flex-direction: row-reverse;
            #{$self}__text {
                padding-right: 0;
                padding-left: 7.5%;
            }
        }
    }

    &__text {
        display: flex;
        align-items: center;
        min-width: 50%;
        width: 50%;
        padding-right: 7.5%;
    }

    &__graph {
        min-width: 50%;
        width: 50%;
        img {
            min-width: 100%;
            width: 100%;
        }
    }

    &__seperator {
        @include seperator;
        margin-bottom: 25px;
    }

    &__title {
        margin-bottom: 30px;
        @include mr;
        font-size: 22px;
        line-height: 24px;
        text-transform: uppercase;
        color: $white;
    }

    &__content {
        margin-bottom: 30px;
        @include manr;
        font-size: 15px;
        line-height: 26px;
        color: $white;
    }

    &--pb-70 {
        padding-bottom: 70px;
    }

    @media(max-width: $break-0){

        &__article {
            padding-left: 20px;
            padding-right: 20px;
            flex-direction: column-reverse;
            &:nth-child(even){
                flex-direction: column-reverse;
                #{$self}__text {
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }

        &__text {
            min-width: 100%;
            width: 100%;
            padding-right: 0;
            padding-bottom: 10px;
        }
    
        &__graph {
            margin-bottom: 30px;
            min-width: 100%;
            width: 100%;
        }

        &__title {
            margin-bottom: 20px;
            font-size: 16px;
            line-height: 24px;
        }

        &__content {
            margin-bottom: 20px;
            font-size: 14px;
            line-height: 26px;
        }

        &--narrow {
            padding-left: 10px;
            padding-right: 10px;
        }

    }
}

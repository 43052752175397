.c-figures {

    &__value {
        margin-bottom: 15px;
        @include mr;
        font-size: 75px;
        line-height: 90px;
        text-align: center;
        text-transform: uppercase;
        color: $whisper;
    }

    &__label {
        @include mb;
        font-size: 20px;
        line-height: 21px;
        text-align: center;
        text-transform: uppercase;
        color: $white;  
    }

    @media(max-width: $break-1){
        &__value {
            font-size: 50px;
            line-height: 60px;
        }
    }

    @media(max-width: $break-0){
        &__label {
            margin-bottom: 50px;
        }
        &__value {
            font-size: 75px;
            line-height: 90px;
        }
    }

}
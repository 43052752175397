.s-parau {

  $self: &;

  #background-color: $whisper;
  padding-top: 70px;

  &__content {
    margin-bottom: 45px;
    @include para--pr22;
    text-align: center;
    color: $white;
  }

  &__seperator {
    @include seperator-center;
  }

  &--inverse {
    background-color: transparent;

    #{ $self }__content {
      color: $white;
    }
  }

  @media(max-width: $break-0){
    padding-top: 0;
    padding-left: 12px;
    padding-right: 12px;

    &__content {
      text-align: left;
      font-size: 16px;
      line-height: 28px;
    }

    &--b0-pt-70 {
      padding-top: 70px;
    }

  }

}

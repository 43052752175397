.s-prodhead {

    $self: &;
  
    padding-top: 90px;
    padding-bottom: 0px;
  
    &__title {
      margin-bottom: 25px;
      @include mb;
      font-size: 21px;
      line-height: 32px;
      text-transform: uppercase;
      text-align: center;
      color: $whisper;
    }
  
    &__content {
      margin-bottom: 30px;
      @include manr;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: $white;
    }

    &__seperator {
        @include seperator-center;
        @include gradient--d;
        margin-bottom: 10px;
      }

    @media(max-width: $break-0){
      padding-top: 10px;
      padding-bottom: 5px;
    
        &__title {
          font-size: 22px;
          line-height: 24px;
        }
    
        &__author {
          font-size: 14px;
          line-height: 24px;
        }

        &__seperator {
            margin-bottom: 40px;
            margin-left: auto;
          }

    }
}



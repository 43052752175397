.c-pagination {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 65px;
  border-top: 1px solid $whisper;
  padding-top: 20px;
  width: 100%;

  &__link {
    flex-basis: 100%;
    @include paginate--mb16;
    text-decoration: none;
    color: $whisper;
    &:hover {
      color: $whisper;
    }

    &--right {
      text-align: right;
    }

  }

  &__number {
    flex-basis: 100%;
    @include paginate--mb16;
    color: $whisper;
    text-align: center;
  }

  &__empty {
    flex-basis: 100%;
    @include paginate--mb16;
    color: rgba($whisper, 25%);

    &--right {
      text-align: right;
    }

  }

  @media(max-width: $break-0){

    margin-bottom: 0;
    margin-top: 40px;

    &__link {
      font-size: 8px;
      line-height: 25px;
    }
    &__number {
      position: absolute;
      left: 0;
      right: 0;
      top: -40px;
      font-size: 8px;
      line-height: 25px;
      text-align: center;
    }
    &__empty {
      font-size: 8px;
      line-height: 25px;
    }
  }

}
.s-newshead {

    $self: &;
  
    padding-top: 90px;
    padding-bottom: 60px;
  
    &__title {
      margin-bottom: 25px;
      @include mb;
      font-size: 21px;
      line-height: 32px;
      text-transform: uppercase;
      text-align: center;
      color: $whisper;
    }
  
    &__author {
      margin-bottom: 30px;
      @include manr;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: $white;
    }

    &__date {
        @include manr;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: $white;
      }


    @media(max-width: $break-0){
      padding-top: 10px;
      padding-bottom: 5px;
    
        &__title {
          font-size: 22px;
          line-height: 24px;
        }
    
        &__author {
          font-size: 14px;
          line-height: 24px;
        }

        &__date {
            font-size: 14px;
            line-height: 24px;
          }

    }
}



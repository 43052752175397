.s-ablock {

  $self: &;

  padding-top: 90px;
  //background-color: $whisper;

  &__para-1 {
    margin-bottom: 70px;
  }
  &__image {
    border-radius: 20px;
    width: 100%;
  }

  &--sharp {
    #{$self}__image {
      border-radius: 0;
    }
  }

  @media(max-width: $break-0){

    padding-left: 12px;
    padding-right: 12px;

    &__para-1 {
      margin-bottom: 0;
    }
  }

}
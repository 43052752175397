.s-pagebody {
    padding-top: 50px;
    color: $white;

    &__title {
        margin-bottom: 30px;
        @include mb;
        font-size: 15px;
        line-height: 23px;
        text-transform: uppercase;
    }

    &__content {
        margin-bottom: 75px;
        @include pnr;
        font-size: 16px;
        line-height: 26px;

        p {
            margin-bottom: 0;
        }
    }

    &__image {
        margin-bottom: 75px;
        img {
            border-radius: 10px;
            width: 100%;
        }
    }

    &__image-dsk {
        display: block;
    }
    &__image-mob {
        display: none;
    }

    // &__twin-image {
    //     margin-bottom: 75px;
    //     display: flex;
    //     gap: 20px;
    //     img {
    //         border-radius: 10px;
    //         width: 100%;
    //     } 
    // }

    &__features-title {
        margin-bottom: 55px;
        @include mb;
        font-size: 22px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        color: $concrete;
    }

    &__features {
        display: flex;
        justify-content: space-around;
        padding-bottom: 100px;
    }

    @media(max-width: $break-0){
        padding-top: 0;


        &__title {
            margin-bottom: 30px;
            padding-left: 20px;
            padding-right: 20px;
        }

        &__content {
            padding-left: 20px;
            padding-right: 20px;
            margin-bottom: 60px;
            font-size: 14px;
            line-height: 21px;
        }
        &__image {
            margin-bottom: 40px;
        }

        &__image-dsk {
            display: none
        }
        &__image-mob {
            display: block;
        }

        // &__twin-image {
        //     flex-direction: column;
        //     margin-bottom: 40px;
        // }
        &__features {
            flex-direction: column;
            padding-bottom: 30px;
        }
    }
}
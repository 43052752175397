.s-figures {

    &__title {
        margin-bottom: 25px;
        @include mb;
        font-size: 22px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        color: $concrete;
    }

    &__flex {
        display: flex;
        justify-content: space-around;
        padding-bottom: 100px;
        padding-left: 10%;
        padding-right: 10%;
    }

    @media(max-width: $break-0){
        &__flex {
            flex-direction: column;
            padding-bottom: 0px;
        }
    }

}
.c-article {

  $self: &;

  margin-left: 40px;
  margin-right: 40px;
  width: 440px;

  &__seperator {
    margin-bottom: 20px;
    @include seperator;
  }

  &__title {
    margin-bottom: 30px;
    @include mr;
    font-size: 22px;
    line-height: 24px;
    text-transform: uppercase;
    color: $whisper;
  }

  &__content {
    margin-bottom: 25px;
    color: $whisper;
  }

  &--inverse {
    #{ $self }__title {
      color: $whisper;
    }
    #{ $self }__content {
      color: $whisper;
    }
  }

  &--edged {
    margin-left: 0;
    margin-right: 0;
  }

  @media(max-width: $break-0){
    width: 100%;

    &__title {
      font-size: 16px;
      line-height: 24px;
    }

    &__button {
      text-align: center;
    }
  }

}

.s-body {
  //@include background-gradient;
  background-color: $cod-gray;
  @include pr;
  font-size: 16px;
  line-height: 26px;
  color: $log-cabin;

  &--modal-open {
    overflow: hidden;
    padding-right: 0px;
  }
}

.c-form {

  $self: &;

  label {
    display: none;
    margin-bottom: 10px;
    @include manr;
    font-size: 16px;
    line-height: 26px;
    color: $white;
  }

  &__recaptcha {
    @include field--psb;
    font-size: 12px;
    line-height: 14px;
    text-decoration: none;
    color: #B1BAC5;

    a {
      color: #8F98A3;
    }

  }

  &__field {
    margin-bottom: 25px;

    input[type='text'], input[type='email'] {
      height: 48px;
      @include field--psb;
      color: $black;
      &::placeholder {
        opacity: 1;
        color: $cadet-blue;
      }
    }

    textarea {
      height: 130px;
      padding-top: 12px;
      @include field--psb;
      color: $black;
      &::placeholder {
        opacity: 1;
        color: $cadet-blue;
      }
    }

    select {
      height: 48px;
      @include field--psb;
      color: $black;
    }

    &--checkbox {
      margin-bottom: 15px;
      input {
        margin-right: 5px;
      }
      label {
        position: relative;
        top: -1px;
        display: inline;
        @include field--psb;
        color: $cadet-blue;
        a {
          color: $cadet-blue;
        }
      }
    }
  }

  &--product {
    #{$self}__field {
      input, select, textarea {
        font-size: 16px;
        line-height: 19px;
        width: 100%;
        border-radius: 6px;
        border: none;
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    #{$self}__recaptcha {
      @include field--psb;
      font-size: 12px;
      line-height: 14px;
      text-decoration: none;
      color: #FFFFFF;
  
      a {
        color: #FFFFFF;
      }
  
    }

    label {
      display: block;  
    }
    input[type='text'], input[type='email'], select {
      height: 60px;
    }
  }
}
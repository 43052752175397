.s-thanks {
  #background-color: $whisper;
  padding-top: 140px;
  padding-bottom: 140px;

  &__title {
    margin-bottom: 10px;
    @include header--mb22;
    text-align: center;
    color: $white;
  }

  &__content {
    @include menu--pr16;
    text-align: center;
    color: $white;
  }

}
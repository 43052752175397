.s-casec {
  margin-left: -.75rem;
  width: calc(100% + 1.5rem);

  .owl-theme .owl-nav {
    position: absolute;
    top: 244px;
    left: 15px;
    right: 15px;
    pointer-events: none;

    .owl-prev {
      float: left;
      font-size: 27px;
      color: $white;
      pointer-events: auto;
      padding: 10px !important;
      background: none;
    }
    .owl-next {
      float: right;
      font-size: 27px;
      color: $white;
      pointer-events: auto;
      padding: 10px !important;
      background: none;
    }
  }

  @media(max-width: $break-0){
    margin-left: .75rem;
    width: calc(100% - 1.5rem);
  }

  @media(max-width: $break-A){
    .owl-theme .owl-nav {
      top: calc(60vw - 80px);
    }
  }

}
.s-contact {
  padding-bottom: 160px;
  #background-color: $whisper;

  &__block {
    display: flex;
    @include shadow;
    background-color: $white;
    margin-left: 5.25%;
    margin-right: 5.25%;
  }

  &__main {
    width: 65%;
    padding: 50px 8% 40px;
  }

  &__aside {
    display: flex;
    flex-direction: column;
    @include background-gradient;
    width: 35%;
    padding: 100px 4% 40px;
    color: $white;
  }

  &__title {
    margin-bottom: 10px;
    @include header--mb22;
    color: $black;
  }

  &__direction {
    margin-bottom: 60px;
    color: $black;
  }

  &__middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }

  &__lower {
    flex: 0;
  }

  &__testimonial {
  }

  &__quote {
    margin-bottom: 20px;
    font-style: italic;
  }

  &__department {
    @include depart--pr14;
  }

  &__company {
    @include company--mb14;
  }

  &__logo-list {
    margin-bottom: 25px;
    @include menu--pr16;
  }

  &__customers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 21px;
    max-height: 21px;

    img {
      margin-bottom: 10px;
      height: 12px;
    }

  }

  @media(max-width: $break-1){
    padding-bottom: 70px;

    &__wrap {
      margin-left: -12px;
      width: calc(100% + 24px);
    }

    &__block {
      flex-direction: column;
      margin-left: 0;
      margin-right: 0;
    }
    &__main {
      width: 100%;
    }
    &__aside {
      width: 100%;
    }

    &__aside {
      padding-top: 30px;
    }

    &__middle {
      justify-content: flex-start;
      margin-bottom: 50px;
    }

    &__quote {
      font-size: 14px;
      padding-left: 12px;
      padding-right: 12px;
    }

    &__department, &__company, &__lower, &__customers {
      padding-left: 12px;
      padding-right: 12px;
    }

  }

}
.c-stat {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    background-color: $white;

    &:nth-child(1){
        grid-area: a;
    }

    &:nth-child(2){
        grid-area: b;
    }

    &:nth-child(3){
        grid-area: c;
    }

    &:nth-child(4){
        grid-area: d;
    }

    &:nth-child(5){
        grid-area: e;
    }

    &:nth-child(6){
        grid-area: f;
    }

    &__wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 302px;
    }

    &__image {
        img {
            width: 107px;
            height: auto;
        }
    }

    &__info {
        padding-left: 25px;
        text-align: left;
    }

    &__count {
        margin-bottom: 12px;
        @include mb;
        font-size: 35px;
        line-height: 35px;
        color: $black;
    }

    &__title {
        @include pr;
        font-size: 18px;
        line-height: 23px;
        color: $black;

        &--large {
            @include mb;
            text-transform: uppercase;
            font-size: 18px;
            line-height: 23px;
        }
    }

    @media(max-width: $lg - 1){
        padding-bottom: 20px;
        padding-top: 20px;

        &__wrap {
            flex-direction: column;
            width: 85px;
        }

        &__image {
            img {
                width: 42px;
            }
        }

        &__info {
            padding-left: 0;
            text-align: center;
        }

        &__count {
            margin-bottom: 0px;
            font-size: 14px;
            line-height: 17px;
        }

        &__title {
            font-size: 10px;
            line-height: 18px;

            &--large {
                @include mb;
                font-size: 8px;
                line-height: 11px;
            }
        }
    }
}
.s-para {

  $self: &;

  color: $white;

  &--pt-50 {
    padding-top: 50px;
  }
  &--pt-90 {
    padding-top: 90px;
  }
  &--pb-45 {
    padding-bottom: 45px;
  }
  &--pb-70 {
    padding-bottom: 70px;
  }
  &--pb-90 {
    padding-bottom: 90px;
  }
  &--pb-150 {
    padding-bottom: 150px;
  }

  &--upper {
    padding-top: 50px;
    padding-bottom: 20px;
  }

  @media(max-width: $break-0){
    padding-bottom: 70px;

    &--b0-pt-10 {
      padding-top: 10px;
    }

    &--b0-pb-25 {
      padding-bottom: 25px;
    }

    &--b0-pb-70 {
      padding-bottom: 70px;
    }

    &__text {
      margin-left: 12px;
      width: calc(100% - 24px);
    }
  }

}
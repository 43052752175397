.s-title {
  //background-color: $log-cabin;
  height: 240px;

  &__title {
    margin-top: 90px;
    margin-bottom: 30px;
    @include header--mb34;
    color: $white;
  }

  &__back {
    a {
      @include back--mb12;
      text-decoration: none;
      color: $white;
      &:hover {
        color: $white;
      }
      i {
        position: relative;
        top: 2px;
        margin-right: 10px;
        font-size: 20px;
        line-height: 26px;
        &:before {
          @include gradient--a;
          background-size: 100%;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-text-fill-color: transparent;
        }
      }
    }
  }

  @media(max-width: $break-0){
    padding-left: 12px;
    padding-right: 12px;

    &__title {
      font-size: 22px;
      line-height: 24px;
    }

  }

}
.c-block {

  $self: &;

  margin-bottom: 100px;

  &:last-child {
    margin-bottom: 0;
  }

  &__flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__wrap {
    position: relative;
  }

  &__cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 30px;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    @include shadow;
    border-radius: 22px;
    border-width: 0;
    background-color: $white;
    width: 220px;
    height: 45px;
    @include mb;
    font-size: 12px;
    text-decoration: none;
    color: $log-cabin;

    &:hover {
      color: $log-cabin;
    }

  }

  &__image {
    border-radius: 20px;
    width: 580px;
    text-align: right;

    &--icon {
      width: 416px;
      margin-right: 15%;
    }

    &--mob {
      display: none;
    }

  }

  &--reverse {
    #{$self}__flex {
      flex-direction: row-reverse;
    }
    #{$self}__image--icon {
      margin-right: 0;
      margin-left: 15%;
    }
  }

  &--sharp {
    #{$self}__image {
      border-radius: 0;
    }
  }

  @media(max-width: $break-1){
    margin-bottom: 45px;

    &__image {
      width: 100%;
    }

    &__flex {
      flex-direction: column-reverse;
    }
    &__image {
      margin-bottom: 20px;
      text-align: center;

      &--icon {
        width: 218px;
        margin-right: 0;
      }
    }

    &__cover {
      padding-bottom: 35px;
    }

    &__button {
      width: 170px;
      height: 36px;
      border-radius: 18px;
      font-size: 10px;
      line-height: 10px;
    }
    &--reverse {
      #{$self}__flex {
        flex-direction: column-reverse;
      }
      #{$self}__image--icon {
        margin-left: 0;
      }
    }

    &--embed-image {
      #{$self}__image {
        &--dsk {
          display: none;
        }
        &--mob {
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

  }

}
.s-footer {
  padding-top: 165px;
  padding-bottom: 75px;

  &__logo {
    margin-bottom:50px;
  }

  &__title {
    margin-bottom: 40px;
    @include mb;
    font-size: 22px;
    line-height: 33px;
    text-transform: uppercase;
    color: $white;
  }

  &__content {
    margin-bottom: 40px;
    width: 520px;
    color: $white;
  }

  &__button {
    margin-bottom: 150px;
  }

  &__seperator {
    margin-bottom: 55px;
    @include seperator-wide;
  }

  &__menu-1 {
    display: flex;
    margin-bottom: 75px;
  }

  &__menu-1ul {
    margin-right: auto
  }

  &__menu-1-item {
    display: inline-block;
    margin-right: 75px;
    a {
      @include manr;
      font-size: 12px;
      line-height: 26px;
      text-transform: uppercase;
      text-decoration: none;
      color: $white;
      &:hover {
        color: $white;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }

  &__icon {
    margin-left: 25px;
    a {
      @include icons--18;
      text-decoration: none;
      color: $white;
      &:hover {
        color: $white;
      }
    }
    &:first-child {
      margin-left: 0;
    }
  }

  &__menu-2 {
    display: flex;
  }

  &__rights {
    @include pnr;
    font-size: 16px;
    line-height: 26px;
    color: $white;
    &:hover {
      color: $white;
    }
  }

  &__menu-2-item {
    display: inline-block;
    margin-left: 55px;
    a {
      @include pnr;
      font-size: 16px;
      line-height: 26px;
      text-decoration: none;
      color: $white;
      &:hover {
        color: $white;
      }
    }
    &:first-child {
      margin-left: 0;
    }
  }

  @media(max-width: $break-0){
    background-image: none !important;
    padding-top: 100px;

    &__logo {
      text-align: center;
    }

    &__title {
      margin-bottom: 10px;
      padding-left: 20%;
      padding-right: 20%;
      font-size: 17px;
      line-height: 24px;
      text-align: center;
    }
    &__content {
      width: auto;
      margin-left: 7%;
      margin-right: 7%;
      font-size: 13px;
      line-height: 21px;
    }
    &__button {
      margin-bottom: 60px;
      text-align: center;
    }

    &__seperator {
      margin-bottom: 25px;
      margin-left: 12px;
      width: calc(100% - 24px);
    }

    &__menu-1 {
      flex-direction: column-reverse;
      margin-bottom: 22px;
      margin-left: 12px;
      margin-right: 12px;
    }

    &__menu-1-item {
      display: block;
      a {
        @include pr;
        font-size: 13px;
        line-height: 21px;
        text-transform: none;
      }
    }

    &__menu-2 {
      margin-left: 12px;
      margin-right: 12px;
      flex-direction: column-reverse;
    }

    &__icon {
      a {
        @include icons--25;
      }
    }

    &__menu-1ul {
      margin-right: 0;
    }

    &__menu-2-item {
      display: block;
      margin-left: 0;
      a {
        font-size: 13px;
        line-height: 21px;
      }
    }

    &__rights {
      font-size: 13px;
      margin-top: 21px;
    }

  }

  &--embed {
    padding-top: 0;
    @media(max-width: $break-0){
      padding-top: 40px;
    }
  }

}

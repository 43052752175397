.c-casec {

  $self: &;

  display: block;
  width: 100%;
  text-decoration: none;

  &__block {
    margin-bottom: 45px;
    @include shadow;
  }

  &__wrap {
    position: relative;
  }

  &__cover {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: flex-end;
    justify-content: center;
    background-color: rgba(0,0,0,.2);
  }

  &__view {
    @include shadow;
    border-radius: 24px;
    background-color: $white;
    margin-bottom: 15px;
    width: 222px;
    height: 48px;
    @include back--mb12;
    line-height: 48px !important;
    text-align: center;
    color: $log-cabin;
  }

  &__info {
    padding-left: 25px;
    padding-right: 25px;
  }

  &__seperator {
    @include seperator;
    margin-bottom: 30px;
  }

  &__title {
    @include header--mb22;
    margin-bottom: 25px;
    color: $black;
  }

  &__content {
    @include menu--pr16;
    color: $black;
  }

  &:hover {
    #{ $self }__cover {
      display: flex;
    }
  }
}
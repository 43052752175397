.s-touch {

  $self: &;

  background-color: $whisper;
  padding-top: 60px;
  padding-bottom: 75px;

  &__title {
    margin-bottom: 30px;
    @include mb;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    text-transform: uppercase;
    color: $log-cabin;
  }

  &__seperator {
    @include seperator-center;
  }

  &__boxv {
    display: flex;
    align-items: center;
    @include shadow;
    background-color: $white;
    height: 220px;
  }

  &__boxh {
    display: flex;
    margin-left: 7.5%;
    margin-right: 7.5%;
    width: 100%;
    justify-content: space-between;
  }

  &__info {
    display: block;
    width: 240px;
    text-align: center;
    text-decoration: none;
    color: $black;

    &:hover {
      color: $black;
    }

  }

  &__icon {
    margin-bottom: 10px;
  }

  &__subtitle {
    @include mb;
    font-size: 18px;
    line-height: 24px;
    color: $log-cabin;
  }

  &__content {
    @include osr;
    font-size: 16px;
    line-height: 24px;

    a {
      text-decoration: none;
      color: $log-cabin;
      &:hover {
        color: $log-cabin;
      }
    }

    &--size-l {
      font-size: 18px;
    }
  }

  &--pb-110 {
    padding-bottom: 110px;
  }

  @media(max-width: $break-1){
    padding-bottom: 70px;

    &__title {
      margin-bottom: 15px;
      font-size: 11px;
      line-height: 17px;
    }
    &__seperator {
      margin-bottom: 20px;
    }
    &__boxh {
      flex-direction: column;
      align-items: center;
      margin-left: 5px;
      margin-right: 5px;
    }
    &__boxv {
      height: auto;
      padding-top: 45px;
      padding-bottom: 45px;

      margin-left: 12px;
      margin-right: 12px;
      width: calc(100% - 24px);

    }
    &__info {
      position: relative;
      padding-top: 30px;
      padding-bottom: 30px;
      &:after {
        position: absolute;
        bottom: 0;
        left: 60px;
        right: 60px;
        border-bottom: 1px solid $alto;
        content: '';
      }
      &:last-child {
        &:after {
          content: none;
        }
      }
    }
  }

  &--embed {
    background-color: transparent;

    #{ $self }__title {
      font-size: 16px;
      color: $white;
    }
    #{ $self }__seperator {
      @include gradient--d;
    }
    #{ $self }__boxv {
      border-radius: 20px;
      @include gradient--e;
    }
    #{ $self }__subtitle {
      color: $white;
    }
    #{ $self }__content {
      color: $white;
      a {
        color: $white;
        &:hover {
          color: $white;
        }
      }
    }

    @media(max-width: $break-0){
      padding-top: 0px;
    }

  }
}

.c-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  border: 0;
  border-radius: 23px;
  width: 222px;
  height: 47px;
  @include mb;
  font-size: 12px;
  line-height: 12px;
  text-decoration: none;
  text-transform: uppercase;
  color: $white;

  &--gradient-a {
    @include gradient--a;
  }

  &--gradient-b {
    @include gradient--b;
    &:hover {
      @include gradient--f;
    }
    &:active {
      background: $white;
      color: $cod-gray !important;
    }
  }

  &--gradient-f {
    @include gradient--f;
    &:hover {
      @include gradient--b;
    }
    &:active {
      background: $white;
      color: $cod-gray !important;
    }
  }

  &:hover {
    color: $white;
  }

  &--white {
    background-color: $white;
    color: $cerulean;
    &:hover {
      color: $cerulean;
    }
  }

  @media(max-width: $break-0){
    &--mob-full {
      width: 100%;
    }
  }

}
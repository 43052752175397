.s-block {

  $self: &;

  padding-bottom: 150px;

  &:after {
    content: "";
    clear: both;
    display: table;
  }

  &__title {
    margin-bottom: 100px;
    @include header--sr34;
    text-align: center;
    color: $log-cabin;
  }

  &__para {
    @include para--pr22;
    margin-bottom: 150px;
    color: $black;
  }

  &--mt {
    padding-top: 100px;
  }

  &--inverse {
    padding-top: 75px;
    padding-bottom: 75px;
    background-color: transparent;
  }

  &--pb-0 {
    padding-bottom: 0;
  }

  &--pt-60 {
    padding-top: 60px;
  }


  &__text-large {
    margin-bottom: 75px;
    @include pr;
    font-size: 22px;
    line-height: 32px;
    color: $black;
  }

  &__text-wide {
    margin-bottom: 75px;
    @include pr;
    font-size: 18px;
    line-height: 22px;
    color: $black;
  }

  &__image-wide {
    margin-bottom: 75px;
    img {
      width: 100%;
      border-radius: 20px;
    }
  }

  &__image-two {
    margin-bottom: 75px;
    img {
      width: 100%;
      border-radius: 20px;
    }
  }

  &--invert {
    background-color: $white;
  }

  &--square {
    #{$self}__image-wide {
      img {
        border-radius: 0;
      }
    }
    #{$self}__image-two {
      img {
        border-radius: 0;
      }
    }
  }

  @media(max-width: $break-0){
    padding-bottom: 75px;

    &__title {
      margin-bottom: 40px;
    }

    &__para {
      margin-bottom: 45px;
      font-size: 16px;
      line-height: 26px;
    }

    &__grid {
      margin-left: 12px;
      width: calc(100% - 24px);
    }

    &__text-large {
      margin-bottom: 40px;
      font-size: 18px;
      line-height: 24px;
    }

    &__text-wide {
      margin-bottom: 40px;
      font-size: 18px;
      line-height: 24px;
    }

    &__image-wide {
      margin-bottom: 40px;
      img {
        border-radius: 10px;
      }
    }
  
    &__image-two {
      margin-bottom: 40px;
      img {
        border-radius: 10px;
      }
    }

    &--mt {
      padding-top: 50px;
    }

    &--b0-narrow {
      #{ $self }__grid {
        margin-left: 12px;
        width: calc(100% - 24px);
      }
    }

    &--b0-pt-0 {
      padding-top: 0;
    }

    &--b0-pb-0 {
      padding-bottom: 0;
    }

    &--b0-pt-45 {
      padding-top: 45px;
    }

    &--square {
      #{$self}__image-wide {
        img {
          border-radius: 0;
        }
      }
      #{$self}__image-two {
        img {
          border-radius: 0;
        }
      }
    }

  }

}
.s-stats {

    padding-top: 100px;

    &__title {
        margin-bottom: 60px;
        @include mr;
        font-size: 30px;
        line-height: 33px;
        text-align: center;
        color: $white;
    }

    &__logo {
        margin-bottom: 75px;
        text-align: center;
        img {
            display: inline-block;
            width: 325px;
        }
    }

    &__perform {
        margin-bottom: 30px;
        @include mb;
        font-size: 22px;
        line-height: 33px;
        text-transform: uppercase;
        text-align: center;
        color: $white;
    }
    
    &__seperator {
        @include seperator-center;
        margin-bottom: 70px;
        &--case {
          @include gradient--d;
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto;
        background-color: #E0E0E0;
        gap: 1px;
        margin-bottom: 70px;
        width: 100%;
        border-radius: 20px;
        overflow: hidden;

        &--3 {
            grid-template-areas: 
                "a a b b c c"
        }
        &--4 {
            grid-template-areas: 
                "a a a b b b"
                "c c c d d d"
        }
        &--5 {
            grid-template-areas: 
                "a a b b c c"
                "d d d e e e"
        }
        &--6 {
            grid-template-areas: 
                "a a b b c c"
                "d d e e f f"
        }
    }

    @media(max-width: $md - 1){

        padding-top: 30px;

        &__title {
            margin-bottom: 15px;
            font-size: 22px;
            line-height: 24px;
        }

        &__logo {
            margin-bottom: 30px;
            img {
                width: 172px;
            }
        }

        &__perform {
            margin-bottom: 20px;
        }

        &__seperator {
            margin-bottom: 45px;
        }

        &__grid {
            margin-bottom: 35px;
    
            &--3 {
                grid-template-areas: 
                    "a b"
                    "c c"
            }
            &--4 {
                grid-template-areas: 
                    "a b"
                    "c d"
            }
            &--5 {
                grid-template-areas: 
                    "a b"
                    "c d"
                    "e e"
            }
            &--6 {
                grid-template-areas: 
                    "a b"
                    "c d"
                    "e f"
            }
        }

    }

}
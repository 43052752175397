.s-modal {

  &__dialog {
    max-width: 75%;
    &.fullscreen {
        max-width: 98%;
        max-height: 98%;
        height: 98%;
     }
  }

  &__content {
    position: relative;
    &.fullscreen {
      height: 98%;
    }
  }

  &__close {
    position: absolute;
    z-index: 10000;
    top: 10px;
    right: 10px;
  }

  &__aspect {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    &.fullscreen {
      height: 100%;
    }
  }

  &__iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  @media(max-width: 575px){
    &__aspect {
      padding-bottom: 177.77%;
    }
    &__dialog {
      max-width: 100%;
    }
  }

}
.c-grid {

  $self: &;

  display: block;
  margin-bottom: 100px;
  width: 370px;
  text-decoration: none;

  &__icon {
    display: block;
    margin-bottom: 35px;
    margin-left: auto;
    margin-right: auto;
    width: 175px;
  }

  &__title {
    margin-bottom: 30px;
    @include mb;
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: $log-cabin;
  }

  &__seperator {
    @include seperator-center;
  }

  &__content {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 250px;
    @include pnr;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: $log-cabin;
  }

  &--dark {
    #{$self}__title {
      color: $white;
    }
    #{$self}__content {
      color: $white;
    }
  }

  @media(max-width: $break-1){

    &--one-cols {
      width: 340px;
    }

    &--two-cols {
      width: 340px;
    }

  }

  @media(max-width: $break-0){

    &__title {
      font-size: 16px;
      line-height: 24px;
    }

    &--one-cols {

      margin-bottom: 50px;

      &:last-child {
        margin-bottom: 50px;
      }

      #{$self}__title {
        margin-bottom: 15px;
      }
      #{$self}__seperator {
        margin-bottom: 15px;
      }
      #{$self}__icon {
        margin-bottom: 20px;
      }
    }

    &--two-cols {

      margin-bottom: 25px;
      width: 140px;

      &:last-child {
        margin-bottom: 50px;
      }

      #{$self}__title {
        margin-bottom: 15px;
        font-size: 10px;
        line-height: 21px;
      }
      #{$self}__seperator {
        margin-bottom: 15px;
      }
      #{$self}__icon {
        margin-bottom: 20px;
        width: 124px;
      }
      #{$self}__content {
        display: none;
      }
    }
  }

}
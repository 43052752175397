.s-prodbody {
    padding-top: 50px;
    color: $white;

    &__title {
        margin-bottom: 30px;
        @include mb;
        font-size: 15px;
        line-height: 23px;
        text-transform: uppercase;
    }

    &__content {
        margin-bottom: 75px;
        @include pnr;
        font-size: 16px;
        line-height: 26px;

        p {
            margin-bottom: 0;
        }
    }

    &__image {
        margin-bottom: 75px;
        img {
            border-radius: 10px;
            width: 100%;
        }
    }

    &__twin-image {
        margin-bottom: 75px;
        display: flex;
        gap: 20px;
        img {
            border-radius: 10px;
            width: calc(50% - 5px);
        } 
    }

    @media(max-width: $break-0){
        padding-top: 40px;
        &__content {
            margin-bottom: 20px;
            font-size: 18px;
            line-height: 24px;
        }
        &__image {
            margin-bottom: 40px;
        }
        &__twin-image {
            flex-direction: column;
            margin-bottom: 40px;
            img {
                width: 100%;
            } 
        }
    }
}
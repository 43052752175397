.s-hbanner {
  padding-top: 20px;
  padding-bottom: 20px;
  color: $white;

  &__wrapper {
    height: 900px;
  }

  &__row {
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
  }

  &__logo {
    margin-bottom: 40px;
  }

  &__title {
    margin-bottom: 50px;
    @include manb;
    font-size: 100px;
    line-height: 120px;
    color: $white;
  }

  &__content {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    width: 100%;
    max-width: 878px;
    @include pnm;
    font-size: 24px;
    line-height: 26px;
  }

  &__button {
    a {
      margin-bottom: 10px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  @media(max-width: $break-3){
    &__title {
      font-size: 50px;
      line-height: 60px;
    }
  }
  
  @media(max-width: $break-1){
    &__row {
      background-size: 70% auto;
    }
  }

  @media(max-width: $break-0){
    padding-bottom: 90px;

    &__wrapper {
      height: 600px;
    }
    &__row {
      background-position: center;
      background-position: top center;
    }

    &__logo {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 250px;
    }
    &__title {
      margin-bottom: 35px;
      margin-top: 275px;
      font-size: 25px;
      line-height: 30px;
    }
    &__content {
      margin-left: auto;
      margin-right: auto;
      width: 250px;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
    }
    &__button {
      text-align: center;
    }
  }

}
.c-cslist {

  $self: &;

  display: block;
  width: calc(33% - 10px);
  text-decoration: none;

  &__block {
    margin-bottom: 45px;
    //@include shadow;
  }

  &__wrap {
    position: relative;
    overflow: hidden;
  }

  &__cover {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,.2);
  }

  &__view {
    @include shadow;
    border-radius: 24px;
    background-color: $whisper;
    width: 222px;
    height: 48px;
    @include back--mb12;
    line-height: 48px !important;
    text-align: center;
    color: $whisper;

    &--case {
      @include gradient--e;
      color: $whisper;
    }

  }

  &__seperator {
    @include seperator;
    margin-bottom: 30px;
    &--case {
      @include gradient--d;
    }
  }

  &__title {
    margin-bottom: 25px;
    @include mr;
    font-size: 22px;
    line-height: 24px;
    text-transform: uppercase;
    color: $whisper;
  }

  &__content {
    margin-bottom: 45px;
    @include pnr;
    font-size: 16px;
    line-height: 26px;
    color: $whisper;
  }

  &:hover {
    #{ $self }__cover {
      display: flex;
    }
  }

  &--inverse {
    #{$self}__view {
      color: $black;
        &--case {
          color: $black;
        }
    }
    #{$self}__title {
      color: $black;
    }
    #{$self}__content {
      color: $black;
    }
  }

  // @media(max-width: 1919px){
  //   width: 406px;
  //   &--wide {
  //     width: calc(33% - 10px);
  //   }

  // }

  // @media(max-width: 1399px){
  //   width: 345px;
  //   &--wide {
  //     width: calc(33% - 10px);
  //   }
  // }

  // @media(max-width: 1199px){
  //   width: 286px;
  //   &--wide {
  //     width: calc(33% - 10px);
  //   }
  // }

  // @media(max-width: $break-1){
  //   width: 48.5%;
  //   padding-left: 10px;
  //   padding-right: 10px;
  //   &--3up {
  //     width: 31%;
  //   }
  //   &--wide {
  //     width: calc(33% - 10px);
  //   }
  // }

  @media(max-width: $break-0){

    &__title {
      font-size: 12px;
      line-height: 26px;
    }

    &__content {
      font-size: 15px;
      line-height: 24px;
    }

    &__info {
      padding-left: 25px;
      padding-right: 25px;
    }
    &--3up {
      width: 100%;
    }
  }

  @media(max-width: $break-A){
    margin-left: 10px;
    width: calc(100% - 20px);
  }

}

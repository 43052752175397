.s-grid {

  $self: &;

  &__title {
    margin-bottom: 100px;
    @include manb;
    font-size: 34px;
    line-height: 38px;
    text-align: center;
    color: $log-cabin;
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &--dark {
    background-color: transparent;
    #{$self}__title {
      color: $white;
    }
  }

  @media(max-width: $break-1){
    &--one-cols {
      #{ $self }__grid {
        justify-content: center;
      }
    }

    &--two-cols {
      #{ $self }__grid {
        justify-content: center;
      }
    }

  }

  @media(max-width: $break-0){
    &__title {
      margin-bottom: 100px;
    }
  }

}
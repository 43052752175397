.c-key {

    &__image {
        margin-bottom: 55px;
        text-align: center;
    }

    &__title {
        margin-bottom: 20px;
        @include mb;
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
        text-align: center;
        color: $white;
    }

    &__seperator {
        @include seperator;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
    }

    &__content {
        padding-left: 18%;
        padding-right: 18%;
        @include pnr;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        color: $white;
    }

}
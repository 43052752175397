.s-header {

  $self: &;

  text-transform: uppercase;
  @include sr;
  font-size: 12px;
  color: $white;

  a {
    text-decoration: none;
    color: $white;
    &:hover {
      color: $white;
    }
  }

  &__logo {
    width: 143px;
    min-width: 143px;
  }

  &__menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 10px;
    border: 1px solid #888888;
    background-color: $black;
    margin-top: 25px; //#
    margin-left: auto; //#
    margin-right: auto; //#
    height: 75px;
    width: 784px; //#
    padding-left: 20px; //#

  }

  &__scroller {
    width: 100%;
  }

  &__center {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__button {
    width: 140px;
    height: 47px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      @include gradient--b;
      width: 100%;
      height: 100%;
      @include mb;
      font-size: 12px;
      color: $concrete;

      &:hover {
        color: $concrete;
      }
    }
  }

  &__burger {
    display: none;
    width: 32px;
    height: 19px;
    border: 0;
    box-shadow: none;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
    background-image: url('/static/images/burger.png');
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url('/static/images/burger@2x.png');
    }
  }

  &__close {
    display: none;
  }

  &__icon-li {
    display: none;
  }

  &__scroller {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__links {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 0;
    padding-left: 0;
  }

  @media(max-width: $break-4){
    &__menu {
      margin-left: 30px;
      margin-right: 30px;
      margin-top: 0;
      border: none;
      background-color: transparent;
      height: 87px;
      width: auto;
    }
    &__link {
      display: none;
    }
    &__button {
      display: none;
    }
    &__burger {
      display: inline-block;
    }
  }


  &--open {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include gradient--g;

    #{$self}__menu {
      justify-content: space-between;
    }

    #{$self}__button {
      display: block;
      margin-bottom: 20px;
      a {
        border-radius: 22px;
        width: 222px;
        background: $white;
        color: $cod-gray;
      }
    }

    #{$self}__burger {
      display: none;
    }

    #{$self}__icon-li {
      display: block;
    }

    #{$self}__close {
      position: absolute;
      display: block;
      top: 20px;
      right: 20px;
      border: 0;
      box-shadow: none;
      width: 19px;
      height: 19px;
      background-color: transparent;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url('/static/images/close.png');
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url('/static/images/close@2x.png');
      }
    }

    #{$self}__scroller {
      position: fixed;
      display: flex;
      top: 100px;
      left: 50px;
      right: 50px;
      width: calc(100% - 100px);
      height: calc(100% - 100px);
      overflow-y: auto;
      justify-content: center;
      align-items: flex-start;
    }

    #{$self}__center {
      width: 300px;
      justify-content: center;
    }

    #{$self}__links {
      flex-direction: column;
      align-items: flex-start;
      width: 220px;
    }

    #{$self}__link {
      display: block;
      padding-top: 20px;
      padding-bottom: 20px;
    }


    #{$self}__icons {
      padding-top: 20px;
      padding-bottom: 40px;
      padding-left: 10px;
      padding-right: 10px;
    }

    #{$self}__icon {
      margin-left: 25px;
      a {
        @include icons--20;
        text-decoration: none;
        color: $white;
        &:hover {
          color: $white;
        }
      }
      &:first-child {
        margin-left: 0;
      }
    }

    @media(max-width: 319px){
      #{$self}__button {
        width: 100%;
      }
      #{$self}__links {
        width: 100%;
      }
    }

  }

}